.ModelPages_TeacherProfile {
    height: auto;
    /* padding: 60px 0px; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    overflow: hidden;
}
.type_1{
    background: linear-gradient(90deg, rgba(66, 10, 9, 1) 0%, rgba(189, 34, 31, 1) 50%, rgba(201, 38, 35, 1) 100%);
    background-size: cover;
}
.type_2{    
    background: linear-gradient(90deg, rgba(6, 16, 43, 1) 0%, rgba(9, 23, 62, 1) 100%);
    background-size: cover;
}
.ModelPages_TeacherProfile .container{
    margin: 0% auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.ModelPages_TeacherProfile .type_1_text{
    align-items: flex-start;
}
.ModelPages_TeacherProfile .type_2_text{
    align-items: flex-end;
}
.ModelPages_TeacherProfile .name_profile{
    display: flex;
    align-items: center;
    gap: 20px;
}
.ModelPages_TeacherProfile .img_profile{
    width: 110px;
    height: 110px;
    border-radius: 110px;
}
.ModelPages_TeacherProfile .div_name{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    text-transform: uppercase;
}
.ModelPages_TeacherProfile .type_1_name{
    color: #ffffff;
    opacity: 0.26;
}
.ModelPages_TeacherProfile .type_2_name{
    color: #ffffff;
    opacity: 0.26;
}
.ModelPages_TeacherProfile .subname{
    color: #FFFFFF;
}
.ModelPages_TeacherProfile .show_data {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
}
.ModelPages_TeacherProfile .title{
    font-family: 'Bebas Neue', sans-serif;
    color: #FFF;
    font-size: 40px;
    text-transform: uppercase;
}
.ModelPages_TeacherProfile .subtitle{
    font-family: 'Bebas Neue', sans-serif;
    color: #FFF;
    font-size: 30px;
    text-transform: uppercase;
}
.ModelPages_TeacherProfile .text{
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #FFF;
    line-height: 26px;
}
.ModelPages_TeacherProfile .portfolio{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}
.ModelPages_TeacherProfile .show_profile{
    flex-direction: row;
    position: absolute;
    z-index: 1;
}
.ModelPages_TeacherProfile .file{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ModelPages_TeacherProfile .img_bg{
    width: 80%;
}

.ModelPages_TeacherProfile .background_image{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
}
.ModelPages_TeacherProfile .img_home{
    width: 100%;
    max-width: 1440px;
    height: -webkit-fill-available;
    margin-bottom: -8px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 1190px) {
    .ModelPages_TeacherProfile .img_home{
        width: 120%;
    }
}
@media only screen and (max-width: 1100px) {
    .ModelPages_TeacherProfile .show_data, .ModelPages_TeacherProfile .file{
        width: 90%;
        align-items: center;
    }
}
@media only screen and (max-width: 870px) {
    .ModelPages_TeacherProfile .show_profile{
        flex-direction: column;
        align-items: center;
    }
    .ModelPages_TeacherProfile .file {
        order: 1;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPages_TeacherProfile .img_profile{
        width: 80px;
        height: 80px;
    }
    .ModelPages_TeacherProfile .div_name{
        font-size: 46px;
    }
}