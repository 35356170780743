.list_data_courses .show_search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0px;
}
.list_data_courses .show_icons_list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid;
}
.list_data_courses .show_type_icon{
    width: 42px;
    height: 42px;
    cursor: pointer;
}
.list_data_courses .div_search{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.list_data_courses .icons_search{
    width: 24px;
    height: 24px;
    display: block;
    cursor: default;
    position: absolute;
    left: 8px;
}
.list_data_courses .input_search{
    background-color: #193C4E;
    border: none;
    color: #FFFFFF;
    padding: 10px 10px 10px 38px;
    width: 200px;
    font-size: 16px;
    outline: none;
}
.list_data_courses .input_search:focus {
    border: none;
    background-color: #193C4E;
}
.list_data_courses .div_select{
    margin-left: 10px;
    position: relative;
}
.list_data_courses .select_category{
    background-color: #193C4E;
    color: #FFFFFF;
    border: none;
    padding: 7px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 282px;
}
.list_data_courses .select_category:focus {
    border: none;
    background-color: #193C4E;
}
.list_data_courses .show_type_triagle{
    display: flex;
}
.list_data_courses .triagle{
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
}
.list_data_courses .triagle_active{
    transform: rotate(180deg);
}
.list_data_courses .list_category{
    position: absolute;
    background-color: #193c4e;
    color: #868686;
    width: -webkit-fill-available;
    margin-top: 10px;
}
.list_data_courses .show_name_category{
    padding: 7px 10px;
    cursor: pointer;
    color: #ffffff;
}
.list_data_courses .show_name_category:hover{
    background-color: #082432;
}
.list_data_courses .list_courses_line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
}
.list_data_courses .list_courses_square{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-bottom: 50px;
}
.list_data_courses .alt_posit{
    justify-content: flex-start;
}
.list_data_courses .list_line{
    background-color: #061821;
    font-size: 12px;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.list_data_courses .list_square{
    background-color: #061821;
    font-size: 12px;
    width: 270px;
    cursor: pointer;
}
.list_data_courses .list_line:hover, .list_data_courses .list_square:hover{
    transform: scale(0.98);
}
.list_data_courses .courses_img{
    height: 140px;
}
.list_data_courses .img_{
    width: 270px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list_data_courses .list_line_courses_data {
    color: #0083C7;
    padding: 10px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.list_data_courses .list_line_courses_data p{
    overflow: hidden;
    height: auto;
}
.list_data_courses .list_square_courses_data {
    color: #0083C7;
    padding: 10px;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
}
.list_data_courses .courses_text{
    color: #ffffff;
    /* padding: 10px 10px 10px 0px; */
    height: 82px;
    max-height: 82px;
}
.list_data_courses .text_align{
    max-height: 38px;
}
.list_data_courses .no_data{
    width: -webkit-fill-available;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
}

@media only screen and (max-width: 910px) {
    .list_data_courses .courses_text{
        max-height: 64px;
        overflow: hidden;
    }
}
@media only screen and (max-width: 700px) {
    .list_data_courses .input_search{
        font-size: 14px;
    }
    .list_data_courses .select_category, .list_data_courses .list_category{
        font-size: 14px;
        width: 240px;
    }
    .list_data_courses .list_category{
        width: -webkit-fill-available;;
    }
}
@media only screen and (max-width: 640px) {
    .list_data_courses .show_icons_list {
        display: none;
    }
    .list_data_courses .list_courses_square{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-bottom: 0px;
        cursor: pointer;
    }
    .list_data_courses .list_square{
        background-color: #061821;
        font-size: 12px;
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    }
    .list_data_courses .courses_img{
        height: auto;
    }
    .list_data_courses .img_{
        width: 190px;
        height: auto;
    }
    .list_data_courses .list_line_courses_data{
        height: auto;
        min-height: auto;
        max-height: 62px;
        padding: 10px 0px;
    }
    .list_data_courses .courses_text{
        max-height: 38px;
    }  
}

@media only screen and (max-width: 580px) {
    .list_data_courses .show_search{
        flex-direction: column;
        gap: 20px;
    }
    .list_data_courses .div_select{
        margin-left: 0px;
        width: -webkit-fill-available;
    }
    .list_data_courses .select_category, .list_data_courses .div_search, .list_data_courses .input_search{
        width: -webkit-fill-available;
    }
}