
.ModelPages_Depositions{    
    height: auto;
    margin: 0% auto;
    background-image: linear-gradient(180deg, #040101, #3A150D);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 60px 0px;
    min-height: 300px;
}
.ModelPages_Depositions .title{
    padding-left: 5px;
    line-height: 60px;    
    text-align: center;    
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    text-transform: uppercase;
    opacity: 0.26;
    color: #ffffff;
}
.ModelPages_Depositions .show_depositions{
    width: 100%;
    overflow: hidden;
}

/* carousel */
.ModelPages_Depositions .carousel{
    overflow: hidden;
}
.ModelPages_Depositions .div_gallery {
    width: 100%;
    height: auto;
    margin: 0% auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 120px;
    position: relative;
}
.ModelPages_Depositions .img_profile{
    width: 149px;
    height: 149px;
    border-radius: 150px;
    pointer-events: none;
}
.ModelPages_Depositions .name{
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}
.ModelPages_Depositions .biography{
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    font-size: 12px;
    font-style: italic;
    text-align: justify;
}
.ModelPages_Depositions .area{
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
}
.ModelPages_Depositions .date{
    color: #895F55;
    font-size: 12px;
}
.ModelPages_Depositions .show_click_img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.ModelPages_Depositions .show_img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    /* margin-top: 50px; */
    width: 100%;
    position: relative;
    min-height: 300px;
}
.ModelPages_Depositions .div_carousel {
    position: absolute;
    /* top: 50%;
    transform: translateY(-50%);*/
    width: 100%;
    height: 100%;
    /* max-height: 28vw; */
}
/* .ModelPages_Depositions .div_carousel:before, .ModelPages_Depositions .div_carousel:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    width: 20%;
    height: 100%;
    z-index: 3;
}
.ModelPages_Depositions .div_carousel:before {
    left: 0;
}
.ModelPages_Depositions .div_carousel:after {
    right: 0;
} */
.ModelPages_Depositions .gallery__stream {
    position: relative;
    /* top: 50%;
    transform: translateY(-50%); */
    width: 100%;
    height: 100%;
}
.ModelPages_Depositions .show_gallery_data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.ModelPages_Depositions .gallery__item {
    position: absolute;
    height: 100%;
    transition: 1s all ease;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
    width: 320px;
    min-width: 320px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}
.ModelPages_Depositions .gallery__item:nth-child(1) {
    left: 0;
    z-index: 1;
    transform: translateX(-100%) scale(.6);
    opacity: 0.6;
}
.ModelPages_Depositions .gallery__item:nth-child(2) {
    left: 0;
    z-index: 2;
    transform: translateX(0%) scale(.6);
    opacity: 0.6;
}
.ModelPages_Depositions .gallery__item:nth-child(3) {
    left: 50%;
    z-index: 4;
    transform: translateX(-50%) scale(1);
}
.ModelPages_Depositions .gallery__item:nth-child(4) {
    left: 100%;
    z-index: 2;
    transform: translateX(-100%) scale(.6);
    opacity: 0.6;
}
.ModelPages_Depositions .gallery__item:nth-child(n+5) {
    left: 100%;
    z-index: 1;
    transform: scale(.6);
    opacity: 0.6;
}

/* Controllers */
.ModelPages_Depositions .gallery__prev, .ModelPages_Depositions .gallery__next {
    position: absolute;
    top: 50%;
    z-index: 4;
    width: 50%;
    height: 100%;
    cursor: pointer;
}
.ModelPages_Depositions .gallery__prev {
    transform: translateX(-30%) translateY(-50%) scale(.8);
}
.ModelPages_Depositions .gallery__next {
    transform: translateX(-68%) translateY(-50%) scale(.8);
}
.ModelPages_Depositions .gallery__prev {
    left: 0;
}
.ModelPages_Depositions .gallery__next {
    left: 100%;
}
.ModelPages_Depositions .img_{
    width: 100%;
}
.ModelPages_Depositions .icons_carousel_prev{
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ModelPages_Depositions .icons_carousel_next{
    width: -webkit-fill-available;
    height: 100%;
    display: flex;
    align-items: center;
}
.ModelPages_Depositions .icons_galerry{
    width: 42px;
    height: 42px;
    display: block;
}
.ModelPages_Depositions .icons_galerry_next{
    transform: rotate(180deg);
}
/* end */

@media only screen and (max-width: 1150px) {
    .ModelPages_Depositions .gallery__item:nth-child(2), .ModelPages_Depositions .gallery__item:nth-child(4){
        width: 250px;
        min-width: 100px;
    }
}
@media only screen and (max-width: 920px) {
    .ModelPages_Depositions .gallery__item:nth-child(2){
        transform: translateX(-100%) scale(.6);
    }
    .ModelPages_Depositions .gallery__item:nth-child(3){
        width: 80%;
        min-width: auto;
    }
    .ModelPages_Depositions .gallery__item:nth-child(4){
        transform: translateX(100%) scale(.6);
    }
    .ModelPages_Depositions .icons_carousel_next, .ModelPages_Depositions .icons_carousel_prev{
        justify-content: center;
    }
}
@media only screen and (max-width: 720px) {
    .ModelPages_Depositions .gallery__item{
        width: 80%;
    }    
    .ModelPages_Depositions .icons_carousel_next{
        justify-content: flex-start;
    }
    .ModelPages_Depositions .icons_carousel_prev{
        justify-content: flex-end;
    }
    .ModelPages_Depositions .gallery__prev {
        transform: translateX(-80%) translateY(-50%) scale(.8);
    }
    .ModelPages_Depositions .gallery__next {
        transform: translateX(-20%) translateY(-50%) scale(.8);
    }
    .ModelPages_Depositions .gallery__item:nth-child(2) {
        left: -100%;
        z-index: 2;
        transform: translateX(-10%) scale(.6);
        opacity: 0.6;
    }
    .ModelPages_Depositions .gallery__item:nth-child(4) {
        left: 200%;
        z-index: 2;
        transform: translateX(-90%) scale(.6);
        opacity: 0.6;
    }
}
@media only screen and (max-width: 670px) {
    .ModelPages_Depositions .div_carousel{
        position: relative;
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPages_Depositions .title{
        font-size: 46px;
    }
    .ModelPages_Depositions .gallery{
        min-width: -webkit-fill-available;
    }
    .ModelPages_Depositions .gallery__prev {
        transform: translateX(-70%) translateY(-50%) scale(.8);
    }
    .ModelPages_Depositions .gallery__next {
        transform: translateX(-30%) translateY(-50%) scale(.8);
    }
}