.ModelPages_VideoPresentation {
    width: 100%;
    height: -webkit-fill-available;
    position: relative;
    padding: 60px 0px;
    min-height: 300px;
    overflow: hidden;
}
.ModelPages_VideoPresentation .show_data {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 0% auto;
}
.ModelPages_VideoPresentation .show_video {
    flex-grow: 1;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ModelPages_VideoPresentation .div_text {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.ModelPages_VideoPresentation .div_text_full {
    width: 100%;
    align-items: center;
}
.ModelPages_VideoPresentation .div_text .div_title {
    line-height: 30px;
    text-transform: uppercase;
}
.ModelPages_VideoPresentation .div_text .subtitle {
    color: #254a5e;
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif
}
.ModelPages_VideoPresentation .div_text .subtitle_center {
    text-align: center;
}
.ModelPages_VideoPresentation .div_text .title {
    color: #254a5e;
    font-size: 40px;
    font-family: 'Bebas Neue', sans-serif;
}
.ModelPages_VideoPresentation .div_text .text {
    /* text-indent: 32px; */
    font-family: 'Roboto', sans-serif;
    color: #6B6B6B;
    font-size: 14px;
    line-height: 20px;
    text-align: justify !important;
}
.ModelPages_VideoPresentation .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-transform: uppercase;
}
.ModelPages_VideoPresentation .button_text {
    color: #ffffff;
    background-color: #7c9f06;
    width: 130px;
    text-align: center;
    padding: 6px 10px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
}
.ModelPages_VideoPresentation .button_text:hover{
    transform: scale(0.96);
}

@media only screen and (max-width: 1020px) {
    .ModelPages_VideoPresentation .show_data{
        flex-direction: column;
    }
    .ModelPages_VideoPresentation .show_video{
        width: 100%;
    }
    .ModelPages_VideoPresentation .div_text{
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .ModelPages_VideoPresentation{
        padding: 30px 0;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPages_VideoPresentation .div_text{
        align-items: center;
    }
    .ModelPages_VideoPresentation .button{
        width: -webkit-fill-available;
    }
    .ModelPages_VideoPresentation .div_text .subtitle, .ModelPages_VideoPresentation .div_text .title{
        text-align: center;
    }
    .ModelPages_VideoPresentation .button{
        margin-top: 20px;
    }
}