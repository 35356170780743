.Page_Courses{
    width: 100%;
    height: auto;
    background-color: #202223;
    min-height: 300px;
}
.Page_Courses .slideshow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-size: cover;
    width: 100%;
    height: auto;
    max-height: 600px;
    padding: 60px 0px 0px;
    background-image: linear-gradient(#0a1e3f, #1a4a9f);
}
.Page_Courses .slideshow .div_slideshow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-size: cover;
}
.Page_Courses .list_icons{
    width: 46%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Page_Courses .show_img{
    width: 46%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}
.Page_Courses .img_courses{
    width: 100%;
    height: fit-content;
    pointer-events: none;
}
.Page_Courses .show_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Courses .width_icon{
    width: 100px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
}
.Page_Courses .div_show_icon{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Page_Courses .show_icons_slide{
    height: 60px;
    width: auto;
}


.Page_Courses .show_type_search{
    position: absolute;
    left: 10px;
}

.Page_Courses .highlight_icons{
    align-items: flex-start;
}
.Page_Courses .show_names{
    margin: 20px 40px;
    color: #ffffff;
}
.Page_Courses .highlight_subtitle{
    font-size: 34px;
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    line-height: 10px;
}
.Page_Courses .highlight_title{
    font-size: 80px;
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    line-height: 80px;
}
.Page_Courses .highlight_text{
    font-size: 14px;
    font-family: 'Inter';
}
.Page_Courses .highlight_button{
    margin-top: 20px;
}

.Page_Courses .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    text-transform: uppercase;
    margin-top: 20px;
}
.Page_Courses .button_text {
    color: #ffffff;
    background-color: #7c9f06;
    width: 130px;
    text-align: center;
    padding: 6px 10px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
}
.Page_Courses .button_text:hover{
    transform: scale(0.96);
}

@media only screen and (max-width: 1070px) {
    .Page_Courses .show_icons_slide {
        height: 30px;
    }
}
@media only screen and (max-width: 960px) {
    .Page_Courses .width_icon{
        font-size: 12px;
    }
}
@media only screen and (max-width: 790px) {
    .Page_Courses .slideshow{
        flex-direction: column;
        gap: 30px;
        padding-top: 80px;
    }
    .Page_Courses .show_names{
        margin: 0px;
    }
    .Page_Courses .highlight_subtitle{
        text-align: center;
        line-height:30px;
    }
    .Page_Courses .highlight_title, .Page_Courses .highlight_text{
        text-align: center;
    }
    .Page_Courses .highlight_icons{
        align-items: center;
    }
    .Page_Courses .button{
        justify-content: center;
    }
    .Page_Courses .slideshow{
        max-height: -webkit-fill-available;
    }
    .Page_Courses .show_img{
        width: 80%;
    }
    .Page_Courses .list_icons{
        width: auto;
    }
}
@media only screen and (max-width: 630px) {
    .Page_Courses .slideshow{
        padding-top: 40px;
    }
    .Page_Courses .slideshow .div_slideshow{
        flex-direction: column;
        gap: 20px;
    }
}
@media only screen and (max-width: 530px) {
}
@media only screen and (max-width: 460px) {
    .Page_Courses .div_show_icon{
        width: 100px;
    }
}