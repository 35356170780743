
.FixedTopic {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 60px;
    background: rgb(0 0 0 / 50%);
    z-index: 9;
}
.FixedTopic .ListMenu{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: -webkit-fill-available;
    position: relative;
}
.FixedTopic .div_logo{
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 60px;
}
.FixedTopic .logotipo{
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    cursor: pointer;
}
.FixedTopic .mobile {
    display: none;
    transition: 1s;
    padding: 10px;
    background-color: #37a7e5;
}
.FixedTopic .div_menu{
    display: flex;
    align-items: center;
    flex-direction: row;
    height: -webkit-fill-available;
}
.FixedTopic .icon_menu{
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
}
.FixedTopic .open_menu {
    opacity: 0;
    height: auto;
    transition: all .3s cubic-bezier(.4, 0, 1, 1) 0s;
}
.FixedTopic .show_opt_menu{
    text-align: center;
    color: #999999;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    color: #ffffff;
}
.FixedTopic .show_opt_menu:hover, .FixedTopic .menu_active{
    background-color: #324d6b;
    border-bottom: 5px solid #37a7e5;
}
.FixedTopic .data_user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.FixedTopic .icon_menu_profile{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    position: relative;
    top: 4px;
}
.FixedTopic .show_data_login{
    display: flex;
    flex-direction: column;
    gap: 4px;
    line-height: 12px;
}
.FixedTopic .type_access{
    font-size: 10px;
    font-family: 'Inter';
    color: #b1b1b1;
    text-transform: uppercase;
}

@media only screen and (max-width: 900px) {
    .FixedTopic .mobile {
        display: flex !important;
        align-items: center;
        justify-content: center;
        transition: 1s;
    }
    .FixedTopic .container{
        width: 100%;
        max-width: 100%;
    }
    .FixedTopic .div_menu{
        padding: 0px 40px;
    }
    .FixedTopic .show_opt_menu{
        height: 50px;
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .FixedTopic .div_menu{
        position: absolute;
        right: -170%;
        opacity: 0;
        padding: 0;
    }
    .FixedTopic .open_menu {
        display: flex !important;
        flex-direction: column;
        position: absolute;
        width: max-content;
        top: 60px;
        padding: 0;
            right: -20px;
        background-color: #030c10;

        animation: open_menu 1s;
        animation-fill-mode: forwards;
    }
    @keyframes open_menu {
        0%{
            right: -20px;
            opacity: 0;
        }
        100% {
            right: 0;
            opacity: 1;
        }
    }
    .FixedTopic .close_menu {
        display: flex !important;
        flex-direction: column;
        position: absolute;
        width: max-content;
        top: 60px;
        padding: 0;
            right: -20px;
        background-color: #030c10;

        animation: open_menu 1s;
        animation-fill-mode: forwards;
    }
    @keyframes close_menu {
        0%{
            right: 0;
            opacity: 1;
        }
        100% {
            right: -30px;
            opacity: 0;
        }
    }
    .FixedTopic .show_opt_menu:hover, .FixedTopic .menu_active{
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 580px) {
    .FixedTopic {
        background-color: #030c10;
    }
    .FixedTopic .div_menu{
        top: 44px;
    }
    .FixedTopic, .FixedTopic .div_logo{
        height: 44px;
    }
    .FixedTopic .icon_menu{
        width: 24px;
        height: 24px;
    }
    .show_opt_menu{
        width: -webkit-fill-available;
    }
    .FixedTopic .open_menu {
        top: 44px;
    }
}