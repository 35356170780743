.ModelPages_ExplanationContents {
    height: auto;
    padding: 60px 0px;
    min-height: 300px;
}
.ModelPages_ExplanationContents .show_data {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 0% auto;
    overflow: hidden;
}
.ModelPages_ExplanationContents .div_video {
    flex-grow: 1;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ModelPages_ExplanationContents .div_text {
    flex-grow: 1;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.ModelPages_ExplanationContents .div_text .div_title {
    line-height: 20px;
    text-transform: uppercase;
    margin-top: 28px;
}
.ModelPages_ExplanationContents .div_text .title {
    color: #254a5e;
    font-size: 60px;
    font-family: 'Bebas Neue', sans-serif;
}
.ModelPages_ExplanationContents .div_text .subtitle {
    color: #254a5e;
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif
}
.ModelPages_ExplanationContents .div_text .text {
    font-size: 14px;
    line-height: 26px;
    text-align: justify !important;
    /* text-indent: 32px; */
}
.ModelPages_ExplanationContents .file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.ModelPages_ExplanationContents .show_icons {
    display: flex;
}
.ModelPages_ExplanationContents .pdf {
    width: 80px;
    height: 80px;
}
.ModelPages_ExplanationContents .text_pdf {
    font-family: 'Bebas Neue';
    font-size: 40px;
    color: #FF5200;
    line-height: 32px;
    text-align: left;
    float: left;
}
.ModelPages_ExplanationContents .list_img {
    width: 48%;
    position: relative;
    height: -webkit-fill-available;
}
.ModelPages_ExplanationContents .img {
    width: 160%;
    position: absolute;
    -webkit-transform: translate(-20%,-50%);
    transform: translate(-20%,-50%);
}
.ModelPages_ExplanationContents .show_file{
    display: flex;
    align-items: center;
    gap: 16px;
}

@media only screen and (max-width: 1100px) {
    .ModelPages_ExplanationContents .div_text, .ModelPages_ExplanationContents .list_img{
        width: 90%;
    }
}
@media only screen and (max-width: 1000px) {
    .ModelPages_ExplanationContents .show_data{
        flex-direction: column;
    }
    .ModelPages_ExplanationContents .img{
        width: 100%;
        position: relative;
        transform: none;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPages_ExplanationContents .text_pdf {
        font-size: 34px;
        line-height: 34px;
    }
}