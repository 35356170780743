.PopUp .recoverPassword{
    width: 420px;
    margin: 20% auto;
}
.PopUp .recoverPassword .div_title{
    border-bottom: 1px solid #c9c9c9;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.PopUp .recoverPassword .button_popup {
    width: -webkit-fill-available;
    padding: 0px
}
.PopUp .recoverPassword .button_popup .btn_save{
    font-size: 16px;
}
.PopUp .recoverPassword .button_save_popup {
    background-color: #76c57e;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: -webkit-fill-available;
    padding: 8px 0px;
}
.PopUp .recoverPassword .div_regi_data{
    width: -webkit-fill-available;
}
.PopUp .recoverPassword .div_input{
    position: relative;
    margin: 30px 0px;
}
.PopUp .recoverPassword .input_email{
    font-family: 'inter';
    font-size: 14px;
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #061821;
}
.PopUp .recoverPassword .title_popup{
    font-weight: 600;
}
.PopUp .recoverPassword .sub_name_input{
    position: absolute;
    left: 8px;
    top: -16px;
    padding: 0px 10px;
    background-color: #061821;
    color: #ffffff;
}
.PopUp .recoverPassword .div_button{
    width: -webkit-fill-available;
}
.PopUp .recoverPassword .show_msg{
    margin: 40px 0px;
}
.PopUp .recoverPassword .return_resposta{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: -webkit-fill-available;
}