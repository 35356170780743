.Page_HowWorks{
    width: 100%;
    height: auto;
    padding: 1px 0px 60px 0px;
    background-color: #ececec;
    margin: 0;
    min-height: 400px;
}
.Page_HowWorks .title{
    color: #000;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 50px;
    text-align: center;
    margin-top: 30px;
}
.Page_HowWorks .text{
    font-family: 'Open Sans', sans-serif;
    max-width: 800px;
    margin: 0% auto;
    margin-top: 20px;
    font-size: 14px;
    color: #3c3c3c;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 40px;
}
.Page_HowWorks .line_color{
    width: 252px;
    height: 2px;
    margin: auto;
    background-color: #2A6E94;
}
.Page_HowWorks .list_doubts{
    max-width: 800px;
    margin: 0% auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.Page_HowWorks .doubts{
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    background-color: #2A6E94;
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
    cursor: pointer;
}
.Page_HowWorks .triagle{
    display: flex;
}
.Page_HowWorks .icons_{
    width: 24px;
    height: 24px;
    transition: 1s;
    transform: rotate(90deg);
}
.Page_HowWorks .icons_active{
    width: 24px;
    height: 24px;
    transition: 1s;
    transform: rotate(180deg);
}
.Page_HowWorks .response_{
    background-color: #dcdcdc;
    padding: 10px;
    font-size: 14px;
    color: #3c3c3c;
    display: none;
}
.Page_HowWorks .doubts_active{
    display: block;
}

@media only screen and (max-width: 460px) {
    .Page_HowWorks .doubts {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
    }
}