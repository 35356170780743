html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #b9b9b9;
    overflow-x: hidden;
    overflow-y: hidden;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
p{
    padding: 0px;
    margin: 0;
}
ul {
    margin: 0px;
    padding: 0px;
}
#home {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.show_content{
    width: 100%;
    height: 100%;
    position: relative;
}
.space_topic{
    height: 60px;
    background-color: #6b6d70;
}
.show_data_page{
    width: 100%;   
}
.container{
    max-width: 1200px;
    margin: 0% auto;
}
.div_video {
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
}
.cursor_default{
    cursor: default !important;
}

/* whatsapp fixed right */
.whatsapp a{
    display: flex;
}
.whatsapp{
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
}
.icon_whatsapp{
    width: 50px;
    height: 50px;
}
/* end */


/* list pages */
.space_not_data{
    padding-bottom: 40px;
}
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
    padding-bottom: 70px;
}
.numb_page{
    background-color: #111827;
    padding: 2px 12px;
    cursor: pointer;
}
.numb_page:hover{
    background-color: #4a566e;
}
.page_active {
    background-color: #5d9200;
}
.no_search{
    color: #111827
}
/* end */


/* input */
.input{
    border: 2px solid #ebebeb;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.span_name_input{
    position: absolute;
    left: 16px;
    transition: 0.5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
.input:valid ~ .span_name_input, .input:focus ~ .span_name_input{
    transform: translateX(0px) translateY(-22px);
    background-color: #ffffff;
    padding: 0 10px;
    left: 6px;
}
.input:valid, .input:focus{
    border: 2px solid #EDEDED;
}
/* end */


/* svg icons with fixed size */
.icons {
    width: 24px;
    height: 24px;
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #111827;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #111827;
}
/* end */

/* responsive */
@media only screen and (max-width: 1190px) {
    .container {
        max-width: 90%;
    }
}
@media only screen and (max-width: 1140px) {
}
@media only screen and (max-width: 767px) {
    .icon_whatsapp {
        width: 60px;
        height: 60px;
    }
}
@media only screen and (max-width: 470px) {
    .space_topic{
        height: 44px;
    }
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 80%);
    font-family: 'Inter';
}
.PopUp .all {
    width: 650px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #061821;
    overflow-y: hidden;
    position: relative;

    animation-duration: 0.7s;
    animation-name: data_modal;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    z-index: 40;
    margin-bottom: 40px;
}
@keyframes data_modal {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
    position: relative;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .div_input{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #2D3C53;
    background-color: #E2E8F0;
    padding: 16px;
}
table tr{
    background-color: #ffffff;
}
table tr:nth-child(2n){
    background-color: #F6FAFF;
}
table td{
    padding: 0px;
    font-size: 12px;
}
/* end */


@media only screen and (max-width: 1190px){ 
    .container {
        max-width: 90%;
    }
}