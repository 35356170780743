.PopUp .CheckedCourse .pop_title {
    font-size: 28px;
    font-family: 'Bebas Neue';
    line-height: normal;
    color: #ffffff;
}
.PopUp .CheckedCourse .pop_text {
    font-size: 14px;
    text-align: center;
    width: 80%;
    margin: 0% auto;
    color: #ffffff;
}
.PopUp .CheckedCourse .pop_video {
    width: 100%;
    height: 340px;
    margin-bottom: 20px;
}
.PopUp .CheckedCourse .pop_div_button{
    font-size: 18px;
    font-family: 'Bebas Neue';
    line-height: normal;
    background-color: #5D9200;
    color: #ffffff;
    width: -webkit-fill-available;
    padding: 10px;
    margin-top: 20px;
}
.PopUp .CheckedCourse .pop_div_button .pop_button{
}