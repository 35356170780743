.ModelPages_StudentWorks {
    max-width: 1440px;
    min-height: 300px;
    height: auto;
    margin: 0% auto;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ModelPages_StudentWorks .show_data {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 0% auto;
    position: absolute;
}
.ModelPages_StudentWorks .img {
    width: 100%;
    height: 100%;
}
.ModelPages_StudentWorks .title {
    font-family: 'Bebas Neue';
    color: #254a5e;
    font-weight: 600;
    font-size: 40px;
    text-transform: uppercase;
}
.ModelPages_StudentWorks .show_video {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 60%;
}
.ModelPages_StudentWorks .background_image{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
}
.ModelPages_StudentWorks .img_home{
    width: 100%;
    height: -webkit-fill-available;
    margin-bottom: -8px;
}

@media only screen and (max-width: 1020px) {
    .ModelPages_StudentWorks .show_data{
        flex-direction: column;        
    }
    .ModelPages_StudentWorks .show_video{
        width: 100%;
        flex-direction: column;
    }
    .ModelPages_StudentWorks .div_text{
        width: 90%;
    }
    .ModelPages_StudentWorks .img_home{
        width: 170%;
    }
}
@media only screen and (max-width: 600px) {
    .ModelPages_StudentWorks .background_image{
        display: none;
    }
}
@media only screen and (max-width: 476px) {
    .ModelPages_StudentWorks .img_home{
        width: 300%;
    }
    .ModelPages_StudentWorks .show_video{
        align-items: center;
    }
}