.Page_Home {
    width: 100%;
    height: auto;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Page_Home_height{
    height: 62%;
}