.ModelPages_Payment{
    width: 100%;
    padding: 60px 0px;
    margin: 0% auto;
    color: #061821;
    background-color: rgb(236, 236, 236);
}
.ModelPages_Payment .title{
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    font-family: 'Bebas Neue';
}
.ModelPages_Payment .subtitle{
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    margin: 0% auto;
}
.ModelPages_Payment .list_table{
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 40px;
}
.ModelPages_Payment .list_inf_payment{
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.ModelPages_Payment .best_choice{
    height: 18px;
}
.ModelPages_Payment .best_choice_active{
    font-size: 12px;
    font-family: 'Inter';
    letter-spacing: normal;
    color: #ffffff;
    background-color: #4C9A00;
}
.ModelPages_Payment .list_title{
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-weight: 700;
}
.ModelPages_Payment .list_subtitle{
    text-align: center;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
}
.ModelPages_Payment .button_access{
    padding: 4px 16px;
    font-family: 'Bebas Neue';
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    color: #ffffff;
    background-color: #4C9A00;
}
.ModelPages_Payment .show_payment{
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ModelPages_Payment .payment_cash {
    font-family: 'Bebas Neue';
    font-size: 60px;
    line-height: 50px;
    display: flex;
    align-items: center;
}
.ModelPages_Payment .show_type_payment{
    font-family: 'Bebas Neue';
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    
}
.ModelPages_Payment .payment_sigla{
    font-size: 20px;
    height: 100%;
    line-height: 1
}
.ModelPages_Payment .payment_data{
    font-size: 20px;  
    display: flex;
    align-items: end;
}
.ModelPages_Payment .td_payment{
    width: 200px;
    padding: 10px;
    border-left: 1px solid rgb(173 173 173 / 30%);
    border-bottom: 1px solid rgb(173 173 173 / 30%);
}
.ModelPages_Payment .space_white{
    height: 300px;
    border-bottom: 1px solid rgb(173 173 173 / 30%);
}
.ModelPages_Payment .space_names{
    height: auto;
    border-bottom: 1px solid rgb(173 173 173 / 30%);
    font-size: 12px;
    font-family: 'Inter';
    text-align: right;
    padding: 10px 10px;
}
.ModelPages_Payment tr:nth-last-child(-n+1) td {
    border-bottom: none !important;
}
.ModelPages_Payment .payment_circle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ModelPages_Payment .payment_active{
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: #4C9A00;
}
.ModelPages_Payment .list_mobile {
    display: none;
}

.ModelPages_Payment .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-transform: uppercase;
}
.ModelPages_Payment .button_text {
    color: #ffffff;
    background-color: #4C9A00;
    width: 280px;
    text-align: center;
    padding: 6px 10px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
}
.ModelPages_Payment .button_text:hover, .ModelPages_Payment .button_access:hover{
    transform: scale(0.96);
}

@media only screen and (max-width: 1190px) {
    .ModelPages_Payment .list_inf_payment{
        width: 166px;
    }
    .ModelPages_Payment .td_payment{
        width: 166px;
    }
}
@media only screen and (max-width: 1130px) {
    .ModelPages_Payment .list_title{
        font-size: 24px;
    }
    .ModelPages_Payment .best_choice_active{
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ModelPages_Payment .list_subtitle{
        font-size: 10px;
    }
}

@media only screen and (max-width: 1050px) {
    .ModelPages_Payment .list_table {
        display: none;
    }
    .ModelPages_Payment .list_mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 20px;
    }
    .ModelPages_Payment .show_payment_mobile{
        width: -webkit-fill-available;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;

        background: #f5f5f5;
        box-shadow: 0 2px 2px 0 #e5e5e5;
        padding: 20px 30px;
    }
    .ModelPages_Payment .payment_mobile{
        width: 300px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid;
    }
    .ModelPages_Payment .payment_type_mobile{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
    }
    .ModelPages_Payment .mobile_title{
        font-family: 'Bebas Neue';
        font-size: 40px;
        line-height: 40px;
    }
    .ModelPages_Payment .mobile_subtitle{
        font-family: 'Inter';
        font-size: 12px;
        text-transform: uppercase;
        height: 50px;
    }
    .ModelPages_Payment .mobile_active{
        background-color: #4C9A00;
        color: #ffffff;
        font-size: 12px;
        font-family: 'Inter';
        display: flex;
        letter-spacing: normal;
        justify-content: center;
    }
    .ModelPages_Payment .button_mobile{
        text-align: center;
        color: #ffffff;
    }
    .ModelPages_Payment .list_category_mobile{
        font-family: 'Inter';
        font-size: 12px;
    }
}

@media only screen and (max-width: 680px) {
    .ModelPages_Payment .show_payment_mobile{
        flex-direction: column;
        margin-bottom: 0;
    }
    .ModelPages_Payment .list_mobile{
        gap: 20px;
    }
    .ModelPages_Payment .payment_mobile{
        border-right: none;
        width: -webkit-fill-available;
        padding: 0;
        margin: 0;
        border-top: 1px solid;
        padding-top: 30px;
        margin-top: 30px;
    }
    .ModelPages_Payment .list_category_mobile{
        width: -webkit-fill-available;
    }
}