.ModelPages_PersonalizedService {
    max-width: 1440px;
    min-height: 300px;
    height: auto;
    margin: 0% auto;
    background: linear-gradient(90deg, rgba(59, 97, 149, 1) 0%, rgba(12, 50, 107, 1) 36%, rgba(2, 16, 38, 1) 100%);
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.ModelPages_PersonalizedService .show_data {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    position: absolute;
}
.ModelPages_PersonalizedService .div_text{
    flex-grow: 1;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
}
.ModelPages_PersonalizedService .title{
    font-family: 'Bebas Neue', sans-serif;
    color: #FFF;
    font-size: 40px;
    text-transform: uppercase;
}
.ModelPages_PersonalizedService .subtitle{
    font-family: 'Bebas Neue', sans-serif;
    color: #FFF;
    font-size: 30px;
    text-transform: uppercase;
}
.ModelPages_PersonalizedService .text{
    /* text-indent: 32px; */
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #FFF;
}
.ModelPages_PersonalizedService .background_image{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
}
.ModelPages_PersonalizedService .img_home{
    width: auto;
    max-width: 1440px;
    height: -webkit-fill-available;
    margin-bottom: -8px;
}

@media only screen and (max-width: 1000px) {
    .ModelPages_PersonalizedService .show_data{
        justify-content: center;
    }
    .ModelPages_PersonalizedService .div_text{
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .ModelPages_PersonalizedService{
        min-height: 900px;
    }
    .ModelPages_PersonalizedService .background_image{
        display: none;
    }
}
@media only screen and (max-width: 530px) {
    .ModelPages_PersonalizedService .text{
        font-size: 14px;
        line-height: 26px;
        text-align: justify !important;
    }
}