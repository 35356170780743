
.ModelPages_ModulesWorkload{
    height: auto;
    padding-top: 60px;
    margin: 0% auto;
    background-color: #071b2f;
    min-height: 300px;
    overflow: hidden;
}
.ModelPages_ModulesWorkload .show_data{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin: 0% auto;
    position: relative;
}
.ModelPages_ModulesWorkload .bg_img{
    width: 46%;
    height: -webkit-fill-available;
}
.ModelPages_ModulesWorkload .img{
    width: 50%;
    height: auto;
    position: absolute;
    bottom: 0px;
}
.ModelPages_ModulesWorkload .div_text{
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 60px;
}
.ModelPages_ModulesWorkload .title{
    font-size: 50px;
    color: #FFAD00;
    padding-left: 5px;
    line-height: 60px;
}
.ModelPages_ModulesWorkload .table{
    font-size: 14px;
    border-spacing: 0px;
    font-family: 'Inter';
    text-transform: capitalize;
    font-weight: 700;
    color: #ffffffb3;
}
.ModelPages_ModulesWorkload .table_numb{
    text-align: center;
    color: #0A2541;
    width: 30px;
    height: 30px;
}
.ModelPages_ModulesWorkload table{
    width: 600px;
}
.ModelPages_ModulesWorkload .table_text{
    background-color: #103860;
    padding-left: 10px
}
.ModelPages_ModulesWorkload .table_time{
    width: 60px;
    text-align: center;
}
.ModelPages_ModulesWorkload .time{
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Inter';
    color: #ffffff;
    font-size: 12px;   
}
.ModelPages_ModulesWorkload .hour{
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
}
.ModelPages_ModulesWorkload .total_hour{
    color: rgba(235, 235, 235, 0.5);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}

@media only screen and (max-width: 1190px) {
    .ModelPages_ModulesWorkload table{
        width: 480px;
    }
}
@media only screen and (max-width: 1100px) {
    .ModelPages_ModulesWorkload .bg_img{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        position: absolute;        
    }
    .ModelPages_ModulesWorkload .img{
        width: 60%;
    }
    .ModelPages_ModulesWorkload .table{
        width: 100%;
    }
    .ModelPages_ModulesWorkload table{
        width: 100%;
    }    
    .ModelPages_ModulesWorkload .div_text{
        align-items: center;
        margin-bottom: 60px;
        gap: 10px;
    }
}
@media only screen and (max-width: 960px) {
    .ModelPages_ModulesWorkload .show_data{
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
    }
    .ModelPages_ModulesWorkload .bg_img{
        justify-content: center;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPages_ModulesWorkload .img{
        width: 100%;
    }
    .ModelPages_ModulesWorkload .div_text{
        padding-bottom:0px;
    }
    .ModelPages_ModulesWorkload .title{
        font-size: 34px;
    }
    .ModelPages_ModulesWorkload .table{
        font-weight: 200;
        font-size: 12px;
    }
    .ModelPages_ModulesWorkload .table_time{
        width: 40px;
    }
}